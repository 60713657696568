<template>
  <div class="loader-wrp" v-if="isVisible">
    <div class="spinner">
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        fill="none"
      >
        <path
          stroke="#78bcc4"
          stroke-width="1.5"
          d="M9.743 10.25c3.213 1.96 5.017 4.676 7.248 4.676 2.588 0 2.791-4.8.518-5.668-3.107-1.187-5.178 3.719-8.284 5.03-1.415.677-3.41 1.014-4.09-1.14-.251-.797-.13-1.65.133-2.442v0c.425-1.278 2.132-1.66 3.35-1.081.304.144.668.346 1.125.625z"
          stroke-dashoffset="100"
          stroke-dasharray="100"
          class="path"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalLoader",
  data: () => {
    return {
      isVisible: false,
    };
  },
  created() {
    window.getApp.$on("LOADER_VISIBILITY", (show) => {
      this.isVisible = show;
      document.documentElement.style.overflow = show ? "hidden" : "auto";
    });
  },
};
</script>

<style lang="scss">
.loader-wrp {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: rgba(250, 250, 250, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.spinner .path {
  animation: infinity-load 1.5s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
}

@keyframes infinity-load {
  to {
    stroke-dashoffset: 10;
  }
}
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #78bcc4;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
</style>
