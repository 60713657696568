export default {
  namespaced: true,
  state: {
    training: null,
  },
  getters: {
    training: (state) => state.training,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {},
};
